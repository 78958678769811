@import "../variables";

button:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}
.button-sucess {
  align-items: center;
  background-clip: padding-box;
  background-color: $primary-color;
  border: 1px solid transparent;
  border-radius: .05rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(2rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  img {
    margin: -3px 10px -3px 10px;
    width: 20px;
    height: auto;
    fill: white;
  }
}
.button-success:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.button-sucess:hover,
.button-sucess:focus {
  background-color: $primary-color-hover;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-sucess:hover {
  transform: translateY(-1px);
}

.button-sucess:active {
  background-color: $primary-color-hover;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}
.button-cancel {
  align-items: center;
  background-clip: padding-box;
  background-color: $cancel-color;
  border: 1px solid transparent;
  border-radius: .05rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(2rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  img {
    width: 20px;
    height: auto;
    fill: white;
    margin: 0px 10px 0px -10px;
  }
}
.button-cancel:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.button-cancel:hover,
.button-cancel:focus {
  background-color: $cancel-color-hover;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-cancel:hover {
  transform: translateY(-1px);
}

.button-cancel:active {
  background-color: $cancel-color;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

.button-light {
  align-items: center;
  background-clip: padding-box;
  background-color: $background-light;
  border: 1px solid transparent;
  border-radius: .05rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(2rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  img {
    margin: 0px 10px 0px -10px;
    width: 20px;
    height: auto;
    fill: white;
  }
}

.button-light:hover,
.button-light:focus {
  background-color: $background-light-hover;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-light:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.button-light:hover {
  transform: translateY(-1px);
}

.button-light:active {
  background-color: $background-light-hover;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

.button-framed {
  align-items: center;
  background-clip: padding-box;
  background-color: #ffffff00;
  border: 1px solid $primary-color;
  border-radius: .05rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  margin-top: 1px;
  min-height: 30px;
  height: 46px;
  padding: calc(.875rem - 1px) calc(2rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  img {
    margin: -3px 10px -3px 10px;
    width: 20px;
    height: auto;
    fill: white;
  }
}
.button-framed:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.button-framed:hover,
.button-framed:focus {
  background-color: $primary-color;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-framed:hover {
  transform: translateY(-1px);
}

.button-framed:active {
  background-color: $primary-color-hover;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}