//color
$background-light: #ffffff;
$background-light-hover: #eeeeee;
$primary-color: #0B0078;
$primary-color-hover: #00CCFF;
$secondary-color: #60c0d8;
$gray-color: #333;
$cancel-color: #ca3030;
$cancel-color-hover: #e73f3f;
//text color
$primary-text-color:                 #000 !default;

//fonts
$font-family-sans-serif:     'Montserrat', "Helvetica", Arial, sans-serif;
