@import "../variables";

.table-container {
  width: 100%;
  overflow-x: auto; /* Dodato za horizontalno skrolovanje na manjim ekranima */
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  table-layout: auto; /* Kolone se automatski prilagođavaju */

}

th, td {
  padding: 5px 15px;
  white-space: nowrap; /* Sprečava prelamanje teksta */
  text-align: left; /* Tekst se poravnava levo */
}

th {
  background-color: $primary-color;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;
}

tr {
  background-color: #fff;
  color: #000;
  font-weight: 400;
  font-size: 16px;
}

tr:hover {
  background-color: #ecf0fb;
  color: #333;
  cursor: pointer;
}

tr:nth-child(even):hover {
  background-color: #ecf0fb;
  color: #333;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #f6f6fa;
  color: #333;
}

.last-column {
  min-width: 100px; /* Podešavanje minimalne širine poslednje kolone */
}

/* Mobile styles */
@media only screen and (max-width: 768px) {
  table {
    width: 100%;
    table-layout: auto; /* Kolone se automatski prilagođavaju */
  }

  th, td {
    font-size: 14px; /* Smanjena veličina fonta za mobilne uređaje */
  }

  .last-column {
    min-width: 80px; /* Smanjena minimalna širina za mobilne uređaje */
  }
}

/* Tablet styles */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  table {
    width: 100%;
    table-layout: auto; /* Kolone se automatski prilagođavaju */
  }

  th, td {
    font-size: 16px;
  }

  .last-column {
    min-width: 100px; /* Podešavanje minimalne širine za tablet uređaje */
  }
}
