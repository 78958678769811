@import "../variables";


//input
.row-checkbox{
  display: flex;
  flex-direction: row;

}
input {
  font-family: $font-family-sans-serif;
  width: 100%;
  padding: 5px 5px;
  border-radius: 1px;
  border: 1px solid #DDE3EC;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  color: #07074D;
  outline: none;
  resize: none;
  height: 40px;
  font-size: 16px !important;
}
input[type="radio" i] {
  height: 20px;
  padding: 0;
  margin: 0px;
  width: auto;
}
input[type="checkbox" i] {
  width: auto;
  height: 20px;
  padding: 0;
  margin: 0px;
}

textarea {
  font-family: $font-family-sans-serif;
  width: 100%;
  font-size: 16px !important;
  padding: 5px 5px;
  border-radius: 1px;
  border: 1px solid #DDE3EC;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  color: #07074D;
  outline: none;
  resize: none;
}

input::placeholder {
  color: #536387;
}

input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
