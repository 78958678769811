// @import '../src/styles/toastr.scss';
@import 'ngx-toastr/toastr';
@import "variables";
@import "styles/button.scss";
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import "styles/form.scss";
@import "styles/table.scss";
body {
  font-family: $font-family-sans-serif;
  margin: 0;
}

.toast-top-right {
  position: fixed !important;
  top: 0;
  right: 0;
  margin: 16px;
}
